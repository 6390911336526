<template>
  <svg viewBox="0 0 64 64">
    <path d="M2,32 a10,10 0 0 1 20,0 a10,10 0 0 0 20,0 a10,10 0 0 1 20,0" class="st0"/>
    <line x1="0" x2="64" y1="32" y2="32" class="st1"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
}
</script>

<style lang="scss" scoped>
svg {
  path {
    fill: transparent;
    stroke-width: 4;
  }

  line {
    stroke-width: 0.5;
    stroke-opacity: 0.8;
  }
}
</style>
